import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Pagination from './Pagination';
import Posts from './Posts';
import popover from './popover';


function Table(props) {
    const evos = useRef([]);
    const browserHistory = useRef({current: 0});
    const evoCount = useRef(0);
    const evoTotal = useRef(0);
    const resultnum = useRef();
    const currentPage = useRef(1);
    const ref = useRef(null);
    const postsPerPage = useRef(parseInt(localStorage.getItem('postsPerPage')) || 20);
    const pageLinkCount = useRef(parseInt(localStorage.getItem('pageLinkCount')) || 10);
    const goblins = useRef(0);

    const [goblins2, setGoblins2] = useState(false);
    const [loading, setLoading] = useState(false);

    var navigate = useNavigate();

    // console.log(props.navUrl); // DELETE LATER

    // localStorage.setItem('testStorage', JSON.stringify({this: 'that', the: 'other'}));
    // var tester = JSON.parse(localStorage.getItem('testStorage'));
    // console.log(tester.this, tester.the);

    const popover_list = {
        serial: 'Returns all records for a specific serial number in descending order. An empty search returns all records in descending order.',
        reg_id: 'Returns all serial numbers for a specific reg id in descending order.',
        evo_version: 'Partial searches are ok (ex. 6.1.5). Returns a list of all records that match the search in descending order.',
        evo_id: 'Returns all records for a specific EVO ID in descending order.',
        disks: 'Returns the latest records for all serial numbers that have a specific number of disks.',
        mac_address: 'Returns all records that have a specific mac address.',
        date_records: 'Returns all records from specified date (YYYY-MM-DD) in descending order.',
        goblins: 'Select this box if you wish to filter out SNS IP, letters in serial, and letters in reg id.',
        failed_smart: '',
        newest_systems: '',
        today_records: ''
    };

    const blankForm = Object.freeze({
        search: '',
        serial: true,
        reg_id: false,
        evo_version: false,
        evo_id: false,
        disks: false,
        mac_address: false,
        failed_smart: false,
        newest_systems: false,
        today_records: false,
        date_records: false
    });

    var searchParams = {
        serial: 'Serial',
        reg_id: 'Reg ID',
        evo_version: 'EVO Version',
        evo_id: 'EVO ID',
        disks: 'Disks',
        mac_address: 'Mac Address',
        failed_smart: 'Failed SMART',
        newest_systems: 'Newest systems',
        today_records: "Today's records",
        date_records: "Custom date records"
    };

    const [newForm, setNewForm] = useState(JSON.parse(JSON.stringify(blankForm)));

    function handleGoblins() {
        setGoblins2(!goblins2);

        if (goblins2) {
            goblins.current = 0;
        } else {
            goblins.current = 1;
        }

        var newData = browserHistory.current[browserHistory.current.current];
        var newerForm = JSON.parse(JSON.stringify(blankForm));
        newerForm.serial = false;
        if (newData.search && newData.search !== 'none') {
            newerForm.search = newData.search;
        }
        newerForm[newData.type] = true;

        GrabEvoData(0, null, newerForm, null, 1);
        ref.current.focus();
    }
    
    function handleChange(event) {
        resultnum.current = null;

        const target = event.target;

        if (target.type === 'radio') {
            var newerForm = JSON.parse(JSON.stringify(blankForm));
            newerForm.search = newForm.search;
            newerForm.serial = false;
            newerForm[target.value] = true;

            setNewForm(newerForm);
            ref.current.focus();
        } else {
            target.className = target.className.replace(' input-error', '');
            target.className = target.className.replace('input-error', '');

            setNewForm(newForm => ({
                ...newForm,
                ...{[target.name]: target.value}
            }));
        }
    }

    function handleHistory(line, direction) {
        let newHistory = {};

        if (direction && direction !== 'none') {
            newHistory = JSON.parse(JSON.stringify(browserHistory.current));
            if (direction === 'forward') {
                newHistory.current = newHistory.current + 1;
            } else if (direction === 'back') {
                newHistory.current = newHistory.current - 1;
            }
        } else {
            for (let x in browserHistory.current) {
                if (x === 'current' || parseInt(x) > browserHistory.current.current) {
                    continue;
                }
                newHistory[x] = browserHistory.current[x];
            }
            newHistory.current = browserHistory.current.current + 1;
            newHistory[Object.keys(newHistory).length.toString()] = line;
        }
        // console.log(newHistory); // DELETE LATER
        browserHistory.current = newHistory;
    }

    function ClearForm(event) {
        event.preventDefault();
        setNewForm(JSON.parse(JSON.stringify(blankForm)));
        evos.current = [];
        resultnum.current = null;
        currentPage.current = 1;
        goblins.current = 0;
        setGoblins2(false);
        navigate('/');
    }

    function GrabEvoData(number, event, data, direction) {
        if (number === currentPage.current) {
            return;
        }
        navigate('/');
        currentPage.current = number || 1;
        evoCount.current = 0;
        setLoading(true);

        if (!data) {
            data = newForm;
        } else {
            setNewForm(data);
        }

        const trueValues = Object.keys(data).filter(function(id) {
            return data[id];
        });

        let newData = {};
        for (let x in trueValues) {
            if (trueValues[x] === 'search') {
                newData['search'] = data[trueValues[x]];
            } else {
                newData['type'] = trueValues[x];
            }
        }

        var page = number;
        if (page !== 0) {
            page = (number - 1) * postsPerPage.current;
        }
        if (event) {
            var target = event.target;
            var inner = target.innerHTML;
            target.disabled = true;
            target.innerHTML = inner + '&nbsp;<i stye="font-size: 40px;" class="fa fa-spinner fa-spin"></i>';
        }

        fetch(`/v1/get_evos/${page}/${newData.type}/${direction || 'none'}/${newData.search || 'none'}/${goblins.current}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': 'Bearer ' + props.token
            }
        })
        .then(response => response.json())
        .then(data => {
            // console.log(data) // DELETE LATER
            if (data.access_token) {
                props.setToken(data.access_token);
            } else if (data.msg === 'Token has expired') {
                props.setLogged(false);
                props.removeToken(props.token);
                navigate('/login');
            }
            if (data.msg) {
                console.log(data.msg);
            }

            evos.current = data.evos || [];
            evoTotal.current = data.count || 0;
            handleHistory(data.history, direction);

            if (data.count > (postsPerPage.current * pageLinkCount.current)){
                evoCount.current = postsPerPage.current * pageLinkCount.current;
            } else {
                evoCount.current = data.count || 0;
            }

            if (event) {
                target.disabled = false;
                // target.className = target.className.replace(' fa fa-spinner fa-spin', '');
                target.innerHTML = inner;
            }
            resultnum.current = 1;
            setLoading(false);
            ref.current.focus();
        });
    }

    function MoveHistory(event) {
        var target = event.target;
        var direction = target.id;
        var newLine = {};
        var existingPage = browserHistory.current.current;

        goblins.current = browserHistory.current[existingPage].goblins;
        // console.log(goblins.current); // DELETE LATER
        // console.log(browserHistory.current); // DELETE LATER

        if (direction === 'forward' && existingPage !== (Object.keys(browserHistory.current).length - 1)) {
            newLine = browserHistory.current[existingPage + 1];
        } else if (direction === 'back' && existingPage !== 1) {
            newLine = browserHistory.current[existingPage - 1];
        } else {
            if (direction === 'forward') {
                props.showNotify('The last record in the search history has been reached.', 2000);
            } else if (direction === 'back') {
                props.showNotify('The first record in the search history has been reached.', 2000);
            } else {
                props.showNotify('Unknown error');
            }
            ref.current.focus();
            return;
        }

        var search = newLine['search'];
        if (search === 'none') {
            search = '';
        }

        var data = {
            "search": search,
            [newLine.type]: true
        }

        var newerForm = JSON.parse(JSON.stringify(blankForm));
        newerForm.search = data.search;
        newerForm.serial = false;
        newerForm[newLine.type] = true;

        var offset = 0;
        if (newLine.offset !== 0) {
            offset = newLine.offset + 1;
        }

        if (goblins.current === 0 && goblins2 === true) {
            setGoblins2(false);
        } else if (goblins.current === 1 && goblins2 === false) {
            setGoblins2(true);
            offset = 0;
        }

        setNewForm(newerForm);
        GrabEvoData(offset, event, data, direction);
    }
    
    function DoSearch(event) {
        event.preventDefault();
        GrabEvoData(0, event);
    }

    function DoLink(event) {
        event.preventDefault();
        resultnum.current = null;
        const target = event.target;
        const type = target.id;

        // console.log(target.className); // DELETE LATER

        var newerForm = JSON.parse(JSON.stringify(blankForm));
        newerForm.search = newForm.search;
        newerForm.serial = false;

        if (type === 'failed-smart') {
            newerForm.failed_smart = true;
        } else if (type === 'newest-systems') {
            newerForm.newest_systems = true;
        } else if (type === 'today-records') {
            newerForm.today_records = true;
        } else if (type === 'date_records') {
            newerForm.date_records = true;
        } else if (type.startsWith('reg_id')) {
            newerForm.search = target.innerHTML;
            newerForm.reg_id = true;
        } else if (type.startsWith('evo_version')) {
            newerForm.search = target.innerHTML;
            newerForm.evo_version = true;
        } else if (type.startsWith('disks')) {
            newerForm.search = target.innerHTML;
            newerForm.disks = true;
        } else if (type.startsWith('serial')) {
            newerForm.search = target.innerHTML;
            newerForm.serial = true;
        }

        setNewForm(newerForm);
        GrabEvoData(0, event, newerForm);
    }

    function showStats() {
        var trueResults = {};
        trueResults['search'] = newForm.search;
        for (let result in newForm) {
            if (result === 'search') {
                continue;
            }
            if (newForm[result]) {
                trueResults['search'] = searchParams[result];
            }
        }

        if (resultnum.current) {
            return (
                <>
                    <div style={{padding: "35px 0 10px 9px"}}>
                        Search: {trueResults.search}<br />
                        Matching Records: {evoTotal.current}<br />
                        Total pages: {Math.ceil(evoTotal.current / postsPerPage.current)}<br />
                        Viewing page: {currentPage.current}
                    </div>
                    <div style={{display: "flex", paddingTop: "20px", paddingLeft: "6px"}}>
                        <button title="Back" type="button" id="back" className='histbutton' onClick={MoveHistory}>&#60;&#60;&#60;</button>
                        <button title="Forward" type="button" id="forward" className='histbutton' onClick={MoveHistory}>&#62;&#62;&#62;</button>
                    </div>
                </>
            );
        }
    }

    useEffect(() => {
        var newerForm = JSON.parse(JSON.stringify(blankForm));
        newerForm.serial = false;

        if (props.navUrl.startsWith('/report')) {
            const queryParams = new URLSearchParams(props.navUrl.replace('/report', ''));
            newerForm[queryParams.get('field')] = true;
            newerForm.search = queryParams.get('value');
        } else {
            newerForm.newest_systems = true;
        }

        GrabEvoData(0, false, newerForm);
    // eslint-disable-next-line
    }, []);

    if (props.initlogged) {
        return (
            <>
                <div className="modal fade" tabIndex="-1" id="monitorModal">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">#</h4>
                                <button type="button"
                                        className="btn-close btn-close-white"
                                        aria-label="Close"
                                        data-bs-dismiss="modal"
                                >
                                </button>
                            </div>
                            <div className="modal-body"></div>
                            <div className="modal-footer"></div>
                        </div>
                    </div>
                </div>
                <form autoComplete="off">
                    <div className="edit-sections">
                        <div className="wrapper">
                            <div className="form-row">
                                <div className="form-label">
                                    Search database:
                                </div>
                                <input onChange={handleChange}
                                    type="text"
                                    value={newForm.search}
                                    name="search"
                                    id="search"
                                    ref={ref}
                                    autoFocus
                                />
                            </div>
                            <div style={{display: "flex", paddingTop: "10px", paddingLeft: "6px"}}>
                                <button type="button" className='logbutton' onClick={ClearForm}>Clear</button>
                                <button type="submit" id="newsubmit" className='logbutton' onClick={DoSearch}>Submit</button>
                            </div>
                            {showStats()}
                        </div>
                        <div className="wrapper selectable">
                            <div className="form-row">
                                <div className="form-label">
                                    Serial:
                                    {popover('Serial', popover_list['serial'])}
                                </div>
                                <label className="checkcontain">
                                    <input onChange={handleChange}
                                        type="radio"
                                        value="serial"
                                        name="search_type"
                                        id="serial"
                                        checked={newForm.serial}
                                    />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <div className="form-row">
                                <div className="form-label">
                                    Reg ID:
                                    {popover('Reg ID', popover_list['reg_id'])}
                                </div>
                                <label className="checkcontain">
                                    <input onChange={handleChange}
                                        type="radio"
                                        value="reg_id"
                                        name="search_type"
                                        id="reg_id"
                                        checked={newForm.reg_id}
                                    />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <div className="form-row">
                                <div className="form-label">
                                    Version:
                                    {popover('Version', popover_list['evo_version'])}
                                </div>
                                <label className="checkcontain">
                                    <input onChange={handleChange}
                                        type="radio"
                                        value="evo_version"
                                        name="search_type"
                                        id="evo_version"
                                        checked={newForm.evo_version}
                                    />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <div className="form-row">
                                <div className="form-label">
                                    EVO ID:
                                    {popover('EVO ID', popover_list['evo_id'])}
                                </div>
                                <label className="checkcontain">
                                    <input onChange={handleChange}
                                        type="radio"
                                        value="evo_id"
                                        name="search_type"
                                        id="evo_id"
                                        checked={newForm.evo_id}
                                    />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <div className="form-row">
                                <div className="form-label">
                                    Disks:
                                    {popover('Disks', popover_list['disks'])}
                                </div>
                                <label className="checkcontain">
                                    <input onChange={handleChange}
                                        type="radio"
                                        value="disks"
                                        name="search_type"
                                        id="disks"
                                        checked={newForm.disks}
                                    />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <div className="form-row">
                                <div className="form-label">
                                    Mac Address:
                                    {popover('Mac Address', popover_list['mac_address'])}
                                </div>
                                <label className="checkcontain">
                                    <input onChange={handleChange}
                                        type="radio"
                                        value="mac_address"
                                        name="search_type"
                                        id="mac_address"
                                        checked={newForm.mac_address}
                                    />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <div className="form-row">
                                <div className="form-label">
                                    Date:
                                    {popover('Date', popover_list['date_records'])}
                                </div>
                                <label className="checkcontain">
                                    <input onChange={handleChange}
                                        type="radio"
                                        value="date_records"
                                        name="search_type"
                                        id="date_records"
                                        checked={newForm.date_records}
                                    />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                        </div>
                        <div className="wrapper">
                            <div className="form-row">
                                <div className="form-label">
                                    Frequent searches:
                                </div>
                                <button className="linkbutton" id="newest-systems" onClick={DoLink}>
                                    Newest systems
                                </button>
                                <input style={{display: 'none'}}
                                    onChange={handleChange}
                                    type="radio"
                                    value="newest_systems"
                                    name="search_type"
                                    id="newest_systems"
                                    checked={newForm.newest_systems}
                                />
                                <button className="linkbutton" id="today-records" onClick={DoLink}>
                                    Today’s records
                                </button>
                                <input style={{display: 'none'}}
                                    onChange={handleChange}
                                    type="radio"
                                    value="today_records"
                                    name="search_type"
                                    id="today_records"
                                    checked={newForm.today_records}
                                />
                                <button className="linkbutton" id="failed-smart" onClick={DoLink}>
                                    Failed SMART
                                </button>
                                <input style={{display: 'none'}}
                                    onChange={handleChange}
                                    type="radio"
                                    value="failed_smart"
                                    name="search_type"
                                    id="failed_smart"
                                    checked={newForm.failed_smart}
                                />
                            </div>
                            <div className="form-row">
                                <div className="form-label">
                                    Hide asterisks:
                                    {popover('goblins', popover_list['goblins'])}
                                </div>
                                <label className="checkcontain">
                                    <input onChange={handleGoblins}
                                        type="checkbox"
                                        value="goblins"
                                        name="hide_goblins"
                                        id="goblins"
                                        checked={goblins2}
                                    />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </form>
            {evos.current.length > 0 &&
                <div className="grid-table">
                    <Pagination
                        postsPerPage={postsPerPage.current}
                        evoCount={evoCount.current}
                        evoTotal={evoTotal.current}
                        GrabEvoData={GrabEvoData}
                        currentPage={currentPage.current}
                        pageLinkCount={pageLinkCount.current}
                    />
                    <div className="my-grid-logged">
                        <div className="grid-header"># (Modal)</div>
                        <div className="grid-header">Serial</div>
                        <div className="grid-header">Reg ID</div>
                        <div className="grid-header">Version</div>
                        <div className="grid-header">Disks</div>
                        <div className="grid-header" style={{paddingLeft: 0}}>Created</div>
                        <div className="grid-header" style={{paddingLeft: 0}}>First Seen</div>
                        <div className="grid-header" style={{paddingLeft: 0}}>Last Seen</div>
                        <div className="grid-header" style={{paddingLeft: 0}}>Lab</div>
                        <Posts posts={evos.current} loading={loading} DoLink={DoLink}/>
                    </div>
                    <Pagination
                        postsPerPage={postsPerPage.current}
                        evoCount={evoCount.current}
                        evoTotal={evoTotal.current}
                        GrabEvoData={GrabEvoData}
                        currentPage={currentPage.current}
                        pageLinkCount={pageLinkCount.current}
                    />
                </div>
            }
            </>
        );
    } else {
        return (
            <div className="grid-table">
                <div>Please log in to search</div>
            </div>
        )
    }
};

export default Table;
