import React from 'react';
import { Outlet, Link, Navigate } from "react-router-dom";
import Header from './Header';
import jwt_decode from "jwt-decode";

function Nav(props) {
    const logOut = () => {
        props.setLogged(false);
        props.showNotify(`Goodbye ${jwt_decode(props.token).sub}.`);
        props.removeToken('token');
    }

    const myLocation = props.location.pathname;

    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    // console.log(myLocation); // DELETE LATER
    // console.log(props.initlogged); // DELETE LATER

    if (props.initlogged) { // Set up links for logged in user
        if (myLocation === '/' || myLocation.startsWith('/report')) {
            return (
            <>
                <div id="head-buttons">
                {jwt_decode(props.token).sub === 'admin' &&
                    <>
                        <button className="logbutton" onClick={() => openInNewTab('/grafana')}>
                            Grafana
                        </button>
                        <Link to="/admin" className="logbutton">Admin</Link>
                    </>
                }
                    <Link to="/login" className="logbutton" onClick={() => logOut()}>Log out</Link>
                </div>
                <div id="container">
                    <Header {...props} />
                    <br />
                    <Outlet />
                </div>
            </>
            )
        } else if (['/admin'].includes(myLocation)) {
            return (
                <>
                    <div id="head-buttons">
                        <Link to="/" className="logbutton">Home</Link>
                        <Link to="/login" className="logbutton" onClick={() => logOut()}>Log out</Link>
                    </div>
                    <div id="container">
                        <Header {...props} />
                        <br />
                        <Outlet />
                    </div>
                </>
                )
        } else if (myLocation === '/login') {
            return (
                <Navigate to="/" replace={false } state={{ lasturl: myLocation + props.location.search }} />
            )
        }
    } else {  // Set up links for logged out user
        if (myLocation === '/login') {
            return (
            <>
                <div id="container">
                    <Header {...props} />
                    <br />
                    <Outlet />
                </div>
            </>
            )
        } else {
            return (
                <Navigate to="/login" state={{ lasturl: myLocation + props.location.search }} />
            )
        }
    }
}

export default Nav;
