import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation } from "react-router-dom";
import Table from './components/Table';
import Nav from './components/Nav';
import Admin from './components/Admin';
import Login from './components/Login';
import useToken from './components/useToken';
// import './components/grid.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';


export default function App() {
    const [ notify, setNotify ] = useState();
    const [ logged, setLogged ] = useState(false);
    const { token, removeToken, setToken } = useToken();
    const [ theme, setTheme ] = useState('Dark');
    const location = useLocation();
    const [ navUrl, setNavUrl ] = useState(location.pathname + location.search);

    useEffect(() => { 
        if (location.state) {
            setNavUrl(location.state.lasturl);
        }
    // eslint-disable-next-line
    }, []);

    // console.log(location); // DELETE LATER

    window.addEventListener('popstate', () => {
        var backDrop = document.querySelector(".modal-backdrop");

        if(backDrop) {
            backDrop.remove();
            document.body.removeAttribute('class');
            document.body.removeAttribute('style');
        }
    });

    var initlogged = false;

    if (localStorage.getItem('token')) {
        initlogged = true;
    }

    function showNotify(message, duration=5000) {
        stopNotify();
        setNotify(message);

        const interval = setTimeout(() => {
            setNotify();
            localStorage.removeItem('notify');
        }, duration);
        // return () => clearTimeout(interval);
        localStorage.setItem('notify', interval);
    }

    function stopNotify() {
        var id = localStorage.getItem('notify');
        if (id) {
            clearTimeout(id);
            localStorage.removeItem('notify');
            setNotify();
        }
    }

    var passedProps = {
        notify: notify,
        showNotify: showNotify,
        stopNotify: stopNotify,
        token: token,
        removeToken: removeToken,
        setToken: setToken,
        logged: logged,
        setLogged: setLogged,
        initlogged: initlogged,
        theme: theme,
        setTheme: setTheme,
        location: location,
        navUrl: navUrl,
        setNavUrl: setNavUrl
    }

    fetch("/api/get_theme", {method: "POST"})
    .then(response => response.json())
    .then(data => {
        if (data.theme) {
            setTheme(data.theme);
        } else {
            console.log(data);
        }
    })

    return (
        <>
            {theme ?
                <link rel="stylesheet" href={`/${theme}.css`}/>
            :
                <link rel="stylesheet" href="/Dark.css" />
            }
            <Routes>
                <Route path="/" element={<Nav {...passedProps} />} >
                    <Route exact path="/" element={<Table {...passedProps} />} />
                    <Route exact path="/admin" element={<Admin {...passedProps} />} />
                    <Route exact path="/login" element={<Login {...passedProps} />} />
                    <Route exact path="/report" element={<Table {...passedProps} />} />
                </Route>
            </Routes>
        </>
    );
}
