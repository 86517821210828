function LogThis(logging, level='critical') {
    var data = {
        logging: logging,
        level: level
    }
    fetch("/api/logthis", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(data => {
        if (data.result === 'FAILED')
            console.log(data);
    })
}

export default LogThis;
