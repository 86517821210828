import React, { useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import popover from './popover';
import jwt_decode from "jwt-decode";

function Admin(props) {
    var navigate = useNavigate();

    const [admin, setAdmin] = useState({
        'username': '',
        'password': '',
        'theme': '',
        'themes': '',
        'results_num': '',
        'links_num': '',
        'admin_email': '',
        'sender_from': '',
        'sender_email': '',
        'sender_password': '',
        'sender_server': '',
        'sender_port': '',
        'email_failed': '',
        'email_success': '',
        'email_weekly_active': ''
    });

    useEffect(() => {
        if (jwt_decode(props.token).sub !== 'admin') {
            navigate('/');
        }

        fetch("/api/get_admin", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Origin": "*",
                "Authorization": 'Bearer ' + props.token
            }
        })
        .then(response => response.json())
        .then(data => {
            if (data.msg === 'Token has expired') {
                props.removeToken('token'); 
                props.setLogged(false);
                navigate('/login');
            }
            if (data.access_token) {
                props.setToken(data.access_token);
            }
            if (data.admin) {
                let areas = document.getElementsByTagName('textarea');

                for (let event of areas) {
                    event.style.height = `${event.scrollHeight}px`;
                }
                setAdmin(data.admin);
            } else {
                console.log(data);
            }
        })
    }, [props, navigate]);

    function NewAdmin(event) {
        event.preventDefault();

        const target = event.target;
        const type = target.id;
        var submitButton = document.getElementById(type);
        submitButton.disabled = true;
        submitButton.innerHTML = 'Submit&nbsp;<i stye="font-size: 40px;" class="fa fa-spinner fa-spin"></i>';

        var data = {
            'username': admin.username,
            'password': admin.password,
            'theme': admin.theme,
            'results_num': admin.results_num,
            'links_num': admin.links_num,
            'admin_email': admin.admin_email,
            'sender_from': admin.sender_from,
            'sender_email': admin.sender_email,
            'sender_password': admin.sender_password,
            'sender_server': admin.sender_server,
            'sender_port': admin.sender_port
        }

        var retError = false;
        var inputError;

        if (!data.username) {
            props.showNotify('Username is a required field');
            retError = true;
            inputError = document.getElementById('username');
            inputError.className = inputError.className + ' input-error';
        }
        if (!data.password) {
            props.showNotify('Password is a required field');
            retError = true;
            inputError = document.getElementById('password');
            inputError.className = inputError.className + ' input-error';
        }
        if (!data.admin_email) {
            props.showNotify('Recipient list is a required field');
            retError = true;
            inputError = document.getElementById('admin_email');
            inputError.className = inputError.className + ' input-error';
        }
        // if (data.email_failed || data.email_success || data.email_weekly_active) {
        //     if (!data.sender_from) {
        //         props.showNotify('Sender from is a required field');
        //         retError = true;
        //         inputError = document.getElementById('sender_from');
        //         inputError.className = inputError.className + ' input-error';
        //     }
        //     if (!data.sender_email) {
        //         props.showNotify('Sender email is a required field');
        //         retError = true;
        //         inputError = document.getElementById('sender_email');
        //         inputError.className = inputError.className + ' input-error';
        //     }
        //     if (!data.sender_password) {
        //         props.showNotify('Sender password is a required field');
        //         retError = true;
        //         inputError = document.getElementById('sender_password');
        //         inputError.className = inputError.className + ' input-error';
        //     }
        //     if (!data.sender_server) {
        //         props.showNotify('Sender server is a required field');
        //         retError = true;
        //         inputError = document.getElementById('sender_server');
        //         inputError.className = inputError.className + ' input-error';
        //     }
        //     if (!data.sender_port) {
        //         props.showNotify('Sender port is a required field');
        //         retError = true;
        //         inputError = document.getElementById('sender_port');
        //         inputError.className = inputError.className + ' input-error';
        //     }
        // }

        // Input field error return
        if (retError) {
            submitButton.disabled = false;
            submitButton.innerHTML = 'Submit';
            return;
        }

        localStorage.setItem('postsPerPage', data.results_num);
        localStorage.setItem('pageLinkCount', data.links_num);

        fetch("/api/admin", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": 'Bearer ' + props.token
            },
            body: JSON.stringify(data)
        })
        .then(response => response.json())
        .then(data => {
            if (data.access_token) {
                props.setToken(data.access_token);
            } else if (data.msg) {
                console.log(data.msg);
            } else {
                console.log(data);
            }
            // console.log(lasturl);
            props.showNotify();
            navigate('/');
            // console.log(origtheme, newtheme)
            // if (origtheme != newtheme) {
            window.location.reload(false);
            // }
        })
    }

    function handleKeyDown(event) {
        event.target.style.height = 'inherit';
        event.target.style.height = `${event.target.scrollHeight}px`; 
    }
    
    function handleChange(event) {
        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        target.className = target.className.replace(' input-error', '');
        target.className = target.className.replace('input-error', '');

        if (value === 'true') {
            value = true;
        } else if (value === 'false') {
            value = false;
        }
    
        setAdmin(admin => ({
            ...admin,
            ...{[name]: value}
        }));
    }

    function testEmail(event) {
        event.preventDefault();

        const target = event.target;
        const type = target.id;
        var testButton = document.getElementById(type);
        testButton.disabled = true;
        testButton.innerHTML = 'Test email&nbsp;<i stye="font-size: 40px;" class="fa fa-spinner fa-spin"></i>';
    
        var data = {
            'admin_email': admin.admin_email,
            'sender_from': admin.sender_from,
            'sender_email': admin.sender_email,
            'sender_password': admin.sender_password,
            'sender_server': admin.sender_server,
            'sender_port': admin.sender_port
        }        
        
        // Check for input field errors
        var retError = false;
        var inputError;        

        if (!data.admin_email) {
            retError = true;
            inputError = document.getElementById('admin_email');
            inputError.className = inputError.className + ' input-error';
        }
        if (!data.sender_from) {
            // props.showNotify('EVO password is a required field');
            retError = true;
            inputError = document.getElementById('sender_from');
            inputError.className = inputError.className + ' input-error';
        }
        if (!data.sender_email) {
            // props.showNotify('Source IP is a required field');
            retError = true;
            inputError = document.getElementById('sender_email');
            inputError.className = inputError.className + ' input-error';
        }
        if (!data.sender_password) {
            // props.showNotify('Source IP is a required field');
            retError = true;
            inputError = document.getElementById('sender_password');
            inputError.className = inputError.className + ' input-error';
        }
        if (!data.sender_server) {
            // props.showNotify('Source IP is a required field');
            retError = true;
            inputError = document.getElementById('sender_server');
            inputError.className = inputError.className + ' input-error';
        }
        if (!data.sender_port) {
            // props.showNotify('Source IP is a required field');
            retError = true;
            inputError = document.getElementById('sender_port');
            inputError.className = inputError.className + ' input-error';
        }

        // Input field error return
        if (retError) {
            testButton.disabled = false;
            testButton.innerHTML = 'Test email';
            return;
        }
        
        const emailTest = async () => {
            const response = await fetch('/api/test_mail', {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Origin': '*',
                    "Authorization": 'Bearer ' + props.token
                },
                body: JSON.stringify(data)
            });
            const json = await response.json();
            console.log(json);

            testButton.innerHTML = 'Test email';
            testButton.disabled = false;
        }
        emailTest();
    }

    const popover_list = {
        'Recipient List': 'Comma delimited list of email addresses who will receive email.',
        'Sender from': 'The name to send email from',
        'Sender email': 'The email address to send email from',
        'Results per page': 'The number of results to show on each page',
        'Links per page': 'The number of page links to make available on each page'
    }

    return (
        <div className="form-div">
            <div className="page-title">Admin</div>
            <form autoComplete="off">
                <div className="admin-sections">
                    <div className="wrapper">
                        <div style={{fontSize: "25px", padding: "20px 0"}}>
                            General Configuration
                        </div>
                        <div className="form-row">
                            <div className="form-label">
                                Theme:
                            </div>
                            <select id="theme" name="theme" value={admin.theme} onChange={handleChange}>
                    {React.Children.toArray(Object.entries(admin.themes).map(([key, themeName]) => (
                                <option key={key} value={themeName}>{themeName}</option>
                    )))}
                            </select>
                        </div>
                        <div className="form-row">
                            <div className="form-label">
                                Admin username:
                            </div>
                            <input onChange={handleChange}
                                type="text"
                                value={admin.username}
                                name="username"
                                id="username"
                            />
                        </div>
                        <div className="form-row">
                            <div className="form-label">
                                Admin password:
                            </div>
                            <input onChange={handleChange}
                                type="password"
                                value={admin.password}
                                name="password"
                                id="password"
                            />
                        </div>
                        <div className="form-row">
                            <div className="form-label">
                                Results per page:
                                {popover('Results per page', popover_list['Results per page'])}
                            </div>
                            <input onChange={handleChange}
                                type="number"
                                value={admin.results_num}
                                name="results_num"
                                id="results_num"
                            />
                        </div>
                        <div className="form-row">
                            <div className="form-label">
                                Links per page:
                                {popover('Links per page', popover_list['Links per page'])}
                            </div>
                            <input onChange={handleChange}
                                type="number"
                                value={admin.links_num}
                                name="links_num"
                                id="links_num"
                            />
                        </div>
                    </div>
                    <div className="wrapper">
                        <div style={{fontSize: "25px", padding: "20px 0"}}>
                            Email Configuration
                        </div>
                        <div className="form-row">
                            <div className="form-label">
                                Recipient List:
                                {popover('Recipient List', popover_list['Recipient List'])}
                            </div>
                            <textarea onChange={handleChange}
                                      id="admin_email"
                                      className="text_box"
                                      name="admin_email"
                                      value={admin.admin_email}
                                      spellCheck="false"
                                      onKeyUp={handleKeyDown}>
                            </textarea>
                        </div>
                        <div className="form-row">
                            <div className="form-label">
                                Sender from:
                                {popover('Sender from', popover_list['Sender from'])}
                            </div>
                            <input onChange={handleChange}
                                type="text"
                                value={admin.sender_from}
                                name="sender_from"
                                id="sender_from"
                            />
                        </div>
                        <div className="form-row">
                            <div className="form-label">
                                Sender email:
                                {popover('Sender email', popover_list['Sender email'])}
                            </div>
                            <textarea onChange={handleChange}
                                      id="sender_email"
                                      className="text_box"
                                      name="sender_email"
                                      value={admin.sender_email}
                                      spellCheck="false"
                                      onKeyUp={handleKeyDown}>
                            </textarea>
                        </div>
                        <div className="form-row">
                            <div className="form-label">
                                Sender password:
                            </div>
                            <input onChange={handleChange}
                                type="password"
                                value={admin.sender_password}
                                name="sender_password"
                                id="sender_password"
                            />
                        </div>
                        <div className="form-row">
                            <div className="form-label">
                                Sender server (SMTP):
                            </div>
                            <input onChange={handleChange}
                                type="text"
                                value={admin.sender_server}
                                name="sender_server"
                                id="sender_server"
                            />
                        </div>
                        <div className="form-row">
                            <div className="form-label">
                                Sender port:
                            </div>
                            <input value={admin.sender_port}
                                type="number"
                                name="sender_port"
                                onChange={handleChange}
                                id="sender_port"
                            />
                        </div>
                        <div style={{display: "flex", justifyContent: "left", marginLeft: "5px", paddingTop: "10px"}}>
                            <button id="test_email" type="button" className="logbutton" onClick={testEmail}>Test email</button>
                        </div>
                    </div>
                </div>
                <div className="form-row" style={{paddingTop: "20px", marginLeft: "-15px"}}>
                    <button type="submit" id="adminsubmit" className='logbutton' onClick={NewAdmin}>Submit</button>
                </div>
            </form>
        </div>
    );
}

export default Admin;
