import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover'
import Button from 'react-bootstrap/Button';

function popover(title, content, placement="bottom", trigger="click") {
    return (<>
        <OverlayTrigger
            placement={placement}
            trigger={trigger}
            rootClose
            overlay={(
                <Popover id="popover-basic">
                    <Popover.Header as="h3">{title}</Popover.Header>
                    <Popover.Body>
                        {content}
                    </Popover.Body>
                </Popover>
            )}
        >
            <Button variant="default" className="popup-button">
                <span className="material-icons help-icon">help</span>
            </Button>
        </OverlayTrigger><br />
    </>);
}

export default popover;
