import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import LogThis from './LogThis';

function Login(props) {
    const blankForm = Object.freeze({
        username: '',
        password: ''
    });

    const [loginForm, setLoginForm] = useState(JSON.parse(JSON.stringify(blankForm)));

    const navigate = useNavigate();

    if (props.location.state && props.location.state.lasturl !== '/login') {
        props.setNavUrl(props.location.state.lasturl);
    }

    function logMeIn(event) {
        event.preventDefault();

        var data = {
            'username': loginForm.username,
            'password': loginForm.password
        }

        var retError = false;
        var inputError;

        if (!data.username) {
            // props.showNotify('Username is a required field');
            retError = true;
            inputError = document.getElementById('username');
            inputError.className = inputError.className + ' input-error';
        }
        if (!data.password) {
            // props.showNotify('Password is a required field');
            retError = true;
            inputError = document.getElementById('password');
            inputError.className = inputError.className + ' input-error';
        }
        // Input field error return
        if (retError) {
            return;
        }

        fetch('/api/login', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            body: JSON.stringify(data)
        })
        .then(response => response.json())
        .then(returned => {
            // console.log(data.result); // DELETE LATER
            if (returned.result === 'FAILED') {
                var wrong_notify = 'Wrong username or password';
                props.showNotify(wrong_notify);
                LogThis(`${wrong_notify}: ${data.username}`, 'info');
            } else if (returned.access_token) {
                props.setToken(returned.access_token);
                props.setLogged(true);
                localStorage.setItem('postsPerPage', returned.results_num);
                localStorage.setItem('pageLinkCount', returned.links_num);
                // console.log('loginlocation:', lasturl); // DELETE LATER
                props.showNotify(`Welcome ${data.username}!`);

                navigate(props.navUrl, {replace: false, state: {lasturl: props.navUrl}});
            } else {
                props.showNotify(returned);
            }
        })
    }

    function handleChange(event) { 
        const {value, name} = event.target
        // console.log(name, value) // DELETE LATER
        setLoginForm(prevNote => ({
            ...prevNote, [name]: value})
    )}

    return (
        <>
            <div className="page-title">Log in</div>
            <form>
                <div className="wrapper">
                    <div className="form-row">
                        <div className="form-label">Username</div>
                        <input onChange={handleChange}
                               type="text"
                               value={loginForm.username}
                               name="username"
                               id="username"
                               autoFocus
                        />
                    </div>
                    <div className="form-row">
                    <div className="form-label">Password</div>
                        <input onChange={handleChange}
                               type="password"
                               value={loginForm.password}
                               name="password"
                               id="password"
                        />
                    </div>
                    <div style={{paddingTop: "20px", marginLeft: "6px"}}>
                        <button className='logbutton' onClick={logMeIn}>Submit</button>
                    </div>
                </div>
            </form>
      </>
    );
}

export default Login;
