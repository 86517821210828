import React from 'react';
import Fader from "./Fader";
import { ModalBodyMonitor, ModalFooterMonitor } from './ModalMonitor';

const Posts = ({ posts, loading, DoLink }) => {
    function getMonitorModal(event) {
        // setModalVisible(true);
        var button = event.target;

        var monitorModal = document.getElementById('monitorModal');
        var modalTitle = monitorModal.querySelector('.modal-title');
        var modalBody = monitorModal.querySelector('.modal-body');
        var modalFooter = monitorModal.querySelector('.modal-footer');

        modalTitle.innerHTML = 'Serial Number: ' + posts[button.id]['serial'];
        modalBody.innerHTML = ModalBodyMonitor(posts[button.id]);
        modalFooter.innerHTML = ModalFooterMonitor(posts[button.id]);
    }

    if (loading) {
        return (
            <Fader text="loading..."></Fader>
        );
    }

    return (
        <>
            {React.Children.toArray(Object.entries(posts).map(([key, post]) => (
                <>
                    <div className="grid-bottom-border row-height">
                        <button id={key}
                                className="item-hover"
                                data-bs-toggle="modal"
                                data-bs-target="#monitorModal"
                                onClick={getMonitorModal}
                        >
                            {post.item}
                        </button>
                    </div>
                    <div className="grid-bottom-border row-height">
                        <span className="mobile-title">Serial:</span>
                        <button id={`serial_${key}`}
                                className="monitor-hover"
                                onClick={DoLink}
                        >
                            {post.serial}
                        </button>
                    </div>
                    <div className="grid-bottom-border row-height">
                        <span className="mobile-title">Reg ID:</span>
                        <button id={`reg_id_${key}`}
                                className="monitor-hover"
                                onClick={DoLink}
                        >
                            {post.reg_id}
                        </button>
                    </div>
                    {/* <div className="row-times grid-bottom-border row-height">
                        <span className="mobile-title">Reg ID:</span> {post.reg_id}
                    </div> */}
                    <div className="grid-bottom-border row-height">
                        <button id={`evo_version_${key}`}
                                className="monitor-hover"
                                onClick={DoLink}
                        >
                            {post.evo_version}
                        </button>
                    </div>
                    {/* <div className="row-times grid-bottom-border row-height">
                        <span className="mobile-title">EVO version:</span> {post.evo_version}
                    </div> */}
                    <div className="grid-bottom-border row-height">
                        <button id={`disks_${key}`}
                                className="monitor-hover"
                                onClick={DoLink}
                        >
                            {post.number_disks}
                        </button>
                    </div>
                    <div className="row-times grid-bottom-border row-height">
                        <span className="mobile-title">Created:</span> {post.created_short}
                    </div>
                    <div className="row-times grid-bottom-border row-height">
                        <span className="mobile-title">First Seen:</span> {post.first_short}
                    </div>
                    <div className="row-times2 grid-bottom-border row-height">
                        <span className="mobile-title">Last Seen:</span> {post.last_short}
                    </div>
                    <div className="row-times2 grid-bottom-border row-height">
                        <span className="mobile-title">Lab:</span> {post.lab_evo}
                    </div>
                </>
            )))}
        </>
    );
};

export default Posts;