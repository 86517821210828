import React from 'react';

function Header(props) {
    function handleClose() {
        props.stopNotify();
    }

    return (
        <>
            <div className="header">
                <div className="header-title">sns</div>
                <div className="header-text">diagnostics</div>
                <div className="header-notify">
                {props.notify ?
                    <button className="row-link notify" onClick={handleClose}>{props.notify}</button>
                :
                    <div className="header-bottom"></div>
                }
                </div>
            </div>
            {/* <div>
                <button className="row-link notify">{props.notify}</button>
            </div> */}
        </>
    );
}

export default Header;
