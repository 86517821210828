function ModalBodyMonitor(props) {
    return (`
        <div>
            <div class="my-grid-modal">
                <div class="table-header grid-bottom-border grid-right-gray">Serial Number:</div>
                <div class="row-times2 grid-bottom-border">
                    ${props.serial}
                </div>

                <div class="table-header grid-bottom-border grid-right-gray">Registration ID:</div>
                <div class="row-times2 grid-bottom-border">
                    ${props.reg_id}
                </div>

                <div class="table-header grid-bottom-border grid-right-gray">EVO ID:</div>
                <div class="row-times2 grid-bottom-border">
                    ${props.evo_id}
                </div>

                <div class="table-header grid-bottom-border grid-right-gray">Created:</div>
                <div class="row-times2 grid-bottom-border">
                    ${props.created}
                </div>
                
                <div class="table-header grid-bottom-border grid-right-gray">First Seen:</div>
                <div class="row-times2 grid-bottom-border">
                    ${props.first_seen}
                </div>

                <div class="table-header grid-bottom-border grid-right-gray">Last Seen:</div>
                <div class="row-times2 grid-bottom-border">
                    ${props.last_seen}
                </div>

                <div class="table-header grid-bottom-border grid-right-gray">Public IP:</div>
                <div class="row-times2 grid-bottom-border">
                    ${props.public_ip}
                </div>

                <div class="table-header grid-bottom-border grid-right-gray">EVO Version:</div>
                <div class="row-times2 grid-bottom-border">
                    ${props.evo_version}
                </div>

                <div class="table-header grid-bottom-border grid-right-gray">Plugins:</div>
                <div class="row-times2 grid-bottom-border">
                    ${props.plugins}
                </div>

                <div class="table-header grid-bottom-border grid-right-gray">Kernel:</div>
                <div class="row-times2 grid-bottom-border">
                    ${props.kernel}
                </div>

                <div class="table-header grid-bottom-border grid-right-gray">Uptime:</div>
                <div class="row-times2 grid-bottom-border">
                    ${props.uptime}
                </div>

                <div class="table-header grid-bottom-border grid-right-gray">System board model:</div>
                <div class="row-times2 grid-bottom-border">
                    ${props.board_model}
                </div>

                <div class="table-header grid-bottom-border grid-right-gray">CPU:</div>
                <div class="row-times2 grid-bottom-border">
                    ${props.cpu}
                </div>

                <div class="table-header grid-bottom-border grid-right-gray">RAM:</div>
                <div class="row-times2 grid-bottom-border">
                    ${props.ram}
                </div>

                <div class="table-header grid-bottom-border grid-right-gray">RAID Controller:</div>
                <div class="row-times2 grid-bottom-border">
                    ${props.raid_controller}
                </div>

                <div class="table-header grid-bottom-border grid-right-gray">Number of disks:</div>
                <div class="row-times2 grid-bottom-border">
                    ${props.number_disks}
                </div>

                <div class="table-header grid-bottom-border grid-right-gray">Volume inode count:</div>
                <div class="row-times2 grid-bottom-border">
                    ${props.volumes}
                </div>

                <div class="table-header grid-bottom-border grid-right-gray">Number of GbE ports:</div>
                <div class="row-times2 grid-bottom-border">
                    ${props.number_gbe}
                </div>

                <div class="table-header grid-bottom-border grid-right-gray">Number of 10GbE ports:</div>
                <div class="row-times2 grid-bottom-border">
                    ${props.number_10gbe}
                </div>

                <div class="table-header grid-bottom-border grid-right-gray">Number of FC ports:</div>
                <div class="row-times2 grid-bottom-border">
                    ${props.number_fc}
                </div>

                <div class="table-header grid-bottom-border grid-right-gray">OS disk SMART:</div>
                <div class="row-times2 grid-bottom-border">
                    ${props.smart}
                </div>

                <div class="table-header grid-bottom-border grid-right-gray">UPS monitoring:</div>
                <div class="row-times2 grid-bottom-border">
                    ${props.ups}
                </div>

                <div class="table-header grid-bottom-border grid-right-gray">Logging level:</div>
                <div class="row-times2 grid-bottom-border">
                    ${props.logging}
                </div>

                <div class="table-header grid-right-gray">Ethernet ports:</div>
                <div class="row-times2">
                    ${props.eth}
                </div>
            </div>
        </div>
    `)
}

function ModalFooterMonitor(props) {
    return (`
            <a href="#" class="logbutton" data-bs-dismiss="modal">Close</a>
    `);
}
  
export { ModalFooterMonitor, ModalBodyMonitor };
