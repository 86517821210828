import React from 'react';

const Pagination = ({ postsPerPage, evoCount, evoTotal, GrabEvoData, currentPage, pageLinkCount }) => {
    const pageNumbers = [];
    const currentPages = Math.ceil(evoCount / postsPerPage);
    
    if (Math.ceil(evoTotal / postsPerPage) <= (pageLinkCount + 1)) {
        for (let i = 1; i <= currentPages; i++) {
            pageNumbers.push(i);
        }
    } else if (currentPage > Math.ceil(evoTotal / postsPerPage) - (pageLinkCount + 1)) {
        for (let i = 0; i < currentPages && (currentPage + i) <= Math.ceil(evoTotal / postsPerPage); i++) {
            pageNumbers.push(currentPage + i);
        }
    } else {
        for (let i = 0; i <= currentPages; i++) {
            pageNumbers.push(currentPage + i);
        }
    }

    return (
        <div>
            <span className="paginate">Page: </span>
        { pageNumbers[0] && currentPage !== 1 &&
            <>
                <span className="page-item" >
                    <button 
                        onClick={() => GrabEvoData(1)}
                        style={{
                            fontSize: "13px", 
                            background: "#888888", 
                            color: "#000000", 
                            borderRadius: "4px", 
                            marginRight: "5px",
                            fontWeight: "bold",
                        }}
                    >
                        &lt;&lt;
                    </button>
                </span>
                <span className="page-item" >
                    <button 
                        onClick={() => GrabEvoData(currentPage - 1)}
                        style={{
                            fontSize: "13px", 
                            background: "#888888", 
                            color: "#000000", 
                            borderRadius: "4px", 
                            marginRight: "5px",
                            fontWeight: "bold",
                        }}
                    >
                        &lt;
                    </button>
                </span>
            </>
        }
            {pageNumbers.map(number => (
                <span key={number} className='page-item'>
                    <button className="paginate-link" onClick={() => GrabEvoData(number)}>
                        {number}
                    </button>
                </span>
            ))}
        { pageNumbers[0] && currentPage !== Math.ceil(evoTotal / postsPerPage) &&
            <>
                <span className="page-item">
                    <button 
                        onClick={() => GrabEvoData(currentPage + 1)}
                        style={{
                            fontSize: "13px", 
                            background: "#888888", 
                            color: "#000000", 
                            borderRadius: "4px",
                            fontWeight: "bold",
                        }}
                    >
                        &gt;
                    </button>
                </span>
                <span className="page-item">
                <button 
                        onClick={() => GrabEvoData(Math.ceil(evoTotal / postsPerPage))}
                        style={{
                            fontSize: "13px", 
                            background: "#888888", 
                            color: "#000000", 
                            borderRadius: "4px", 
                            fontWeight: "bold", 
                            marginLeft: "5px"}}
                    >
                        &gt;&gt;
                    </button>
                </span>
            </>
        }
        </div>
    );
};

export default Pagination;
